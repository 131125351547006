import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import i18n from "../i18n";
import React, {createElement, Suspense} from "react";
import {render, unmountComponentAtNode} from "react-dom";
import store from "../store";
import {setCart, setLoading} from "../features/cart/cartSlice";
import {client} from "./Client";
import Cookies from 'universal-cookie';
import {theme} from "./theme";
import {toast} from "react-toastify";
import HeaderButtons from "../components/HeaderButtons";
import {Provider} from "react-redux";


export const formatMoney = (money) => {

    const val = parseInt(money.amount)/100;
    const currency = money.currency.code;

    return i18n.t('intlCurrency', { val: val, currency: currency })
};

export const formatRaceName = (race) => {
    let output = '';
    if (race.event) {
        output = `${race.event.name} ${race.event.place} - ${race.event_type.name}`
    } else {
        output = `${race.name} ${race.place}`
    }
    output = `${output}, ${formatDates(race)}`;


    return output;
}

export const formatDates = (event) => {
    const locale = i18n.t('locale');

    let date = '';
    const options = { day: 'numeric', month: 'long', year: "numeric" };
    const fullDate = new Date(event.date)
    if (event.date_to) {
        const fullDateTo = new Date(event.date_to);
        date = fullDate.toLocaleDateString(locale, {day: "numeric"});
        date += " - " + fullDateTo.toLocaleDateString(locale, options);
    } else {
        const fullDate = new Date(event.date)
        date = fullDate.toLocaleDateString(locale, options);
    }
    return date;

}
export const formatDate = (fullDate, year=true) => {
    const locale = i18n.t('locale');

    let date = '';
    const options = { day: 'numeric', month: 'long'};
    if (year) {
        options["year"] = "numeric";
    }
    return fullDate.toLocaleDateString(locale, options);

}


/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export const post = (path, params={}, method='post') => {

    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export const get = (path) => {
    top.location.href = path;
}


export const mountReactElement = (Element, name) => {
    const AppElement = (props) => {
        return <ThemeProvider theme={theme}>
            <Suspense fallback="...">
                <Provider store={store}>
                    <Element {...props}/>
                </Provider>
            </Suspense>
        </ThemeProvider>
    }


    class AppHtmlElement extends HTMLElement {
        connectedCallback() {
            const props = Object.values(this.attributes).map(attribute => {
                let value
                try {
                    value = JSON.parse(attribute.value);
                } catch (SyntaxError) {
                    value = attribute.value;
                }
                return [attribute.name, value];
            });
            render(createElement(AppElement, Object.fromEntries(props)), this)
        }

        disconnectedCallback() {
            unmountComponentAtNode(AppElement)
        }
    }

    customElements.define(name, AppHtmlElement)

}

export const getPriceForDate = (now, prices, currency = 'PLN') => {
    const returnPrice = (price) => {return {
        dateFrom: price.date_from,
        dateTo: price.date_to,
        price: price.prices.filter(item => item.currency.code == currency)[0]
    }}

    let lastPrice = null;
    let lastPriceDate = null;
    for (const price of prices) {

        const dateFrom = new Date(price.date_from);
        if (lastPrice === null || lastPriceDate < dateFrom) {
            lastPrice = price;
            lastPriceDate = new Date(lastPrice.date_from)
        }

        if (now >= dateFrom) {

            if (price.date_to === null) {
                return returnPrice(price);
            }
            const dateTo = new Date(price.date_to);
            dateTo.setHours(23)
            dateTo.setMinutes(59)
            dateTo.setSeconds(59)
            if (now <= dateTo) {

                return returnPrice(price);
            }
        }
    }

    return returnPrice(lastPrice);
}

export const getCurrentPrice = (prices, currency = 'PLN') => {
    const now = new Date();
    return getPriceForDate(now, prices, currency)
}

export const getNextPrice = (prices, currency = 'PLN') => {
    const currentPrice = getCurrentPrice(prices, currency);
    const dateTo = new Date(currentPrice.dateTo);
    dateTo.setDate(dateTo.getDate() + 1);

    return getPriceForDate(dateTo, prices, currency);
}

export const getOmnibusPrice = (prices, currency = 'PLN') => {
    const returnPrice = (price) => {return {
        dateFrom: price.date_from,
        dateTo: price.date_to,
        price: price.prices.filter(item => item.currency.code == currency)[0]
    }};

    const startDate = new Date(); // today!
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setDate(startDate.getDate() - 30);

    let lastPrice = null;
    let lastPriceDate = null;

    const potentialPrices = [];

    for (const price of prices) {

        const dateFrom = new Date(price.date_from);
        if (lastPrice === null || lastPriceDate < dateFrom) {
            lastPrice = price;
            lastPriceDate = new Date(lastPrice.date_from)
        }

        if (startDate >= dateFrom) {
            if (price.date_to === null) { // last one
                potentialPrices.push(price);
            }
            const dateTo = new Date(price.date_to);
            dateTo.setHours(23)
            dateTo.setMinutes(59)
            dateTo.setSeconds(59)
            if (startDate <= dateTo) {

                potentialPrices.push(price);
            }
        }
    }

    if (potentialPrices.length !== 0) {
        potentialPrices.sort((a,b) => {
            const amountA = a.prices.filter( p => p.currency == currency)[0]
            const amountB = b.prices.filter( p => p.currency == currency)[0]
            return amountA > amountB;
        })
        return returnPrice(potentialPrices[0]);
    }

    return returnPrice(lastPrice);
}

export const DateDiff = {

    inDays: function(d1, d2 = null) {
        if (d2 === null) {
            d2 = new Date();
        }
        if (typeof d1 === "string") {
            d1 = new Date(d1);
        }
        if (typeof d2 === "string") {
            d2 = new Date(d2);
        }
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.abs(Math.floor((t2-t1)/(24*3600*1000)));
    },

    inWeeks: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000*7));
    },

    inMonths: function(d1, d2) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return (d2M+12*d2Y)-(d1M+12*d1Y);
    },

    inYears: function(d1, d2) {
        return d2.getFullYear()-d1.getFullYear();
    }
}

export const objectFromKeys = (obj, def) => Object.fromEntries(Object.keys(obj).map(i => [i, def]));


export const refreshCart = () => {
    store.dispatch(setLoading);
    return client.cart().then((response) => {
        const cart = response.data;
        const ref = localStorage.getItem('ref');
        let valid = true;
        if (ref) {
            if (cart.items.length === 0) {
                if ( localStorage.getItem('ref_shown') !== '1') {
                    toast.info("Kod zniżkowy " + ref + " został zapisany. Dodaj produkty do koszyka i zostanie on zastosowany automatycznie");
                    localStorage.setItem('ref_shown', '1');
                }
            } else {
                localStorage.removeItem('ref');
                localStorage.removeItem('ref_shown');
                return client.createDiscountCode(cart.id, ref).then((response) => {
                    refreshCart();
                    toast.info("Kod zniżkowy " + ref + " został zastosowany");

                }).catch(() => {
                    refreshCart();
                })
            }
        }
        if (valid) {
            store.dispatch(setCart(cart))
        }
        return response.data;
    }).catch(() => {
        const ref = localStorage.getItem('ref');

        if (ref && localStorage.getItem('ref_shown') !== '1') {
            toast.info(<>Kod zniżkowy <b>{ref}</b> został zapisany. Dodaj produkty do koszyka i zostanie on zastosowany automatycznie</>);
            localStorage.setItem('ref_shown', '1');
        }
    });
}

export const isCartValid = (cart) => {
    if (cart.payment_required) {
        if (!cart.payment) {
            return false;
        }
    }
    if (cart.shipping_required) {
        if (!cart.shipping) {
            return false;
        }
        if (cart.shipping.pickup_point) {
            if (cart.pickup_point_data.id === null) {
                return false;
            }
        }
    }
    return true;
}

export const wildcardSearch = (wildcard, str)  => {
    let w = wildcard.replace(/[.+^${}()|[\]\\]/g, '\\$&'); // regexp escape
    const re = new RegExp(`^${w.replace(/\*/g,'.*').replace(/\?/g,'.')}$`,'i');
    return re.test(str); // remove last 'i' above to have case sensitive
}

export const calculateAgeAtDate = (birth_date, comparison_date = null) => {
    const birthDate = new Date(birth_date);
    const comparisonDate = new Date(comparison_date);

    let years = (comparisonDate.getFullYear() - birthDate.getFullYear());

    if (comparisonDate.getMonth() < birthDate.getMonth() ||
        comparisonDate.getMonth() == birthDate.getMonth() && comparisonDate.getDate() < birthDate.getDate()) {
        years--;
    }

    return years;
}

export const prepareLabel = (content) => {
    const allowedTags = ['a','b','i','u','br'];
    const keep = allowedTags.join('|')
    const regex =new RegExp(`<(?!\\/?(${keep})\\s*\\/?)[^>]+>`, 'ig')

    return content.replace(regex, '');
}

export const scrollToElement = (element) => {
    const top = element.offsetTop
    const hh = document.getElementsByTagName('header')[0].offsetHeight;
    window.scroll({top: top-hh, left: 0, behavior: 'smooth' })
}

export const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
};
