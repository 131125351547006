import React, {useRef, useState} from 'react';
import {formatMoney} from "../../../lib/utils";
import {
    Alert,
    Box, Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    IconButton, InputAdornment, InputBase, LinearProgress,
    Stack, TextField,
    Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {debounce} from "debounce";

const participantName = (p) => {
    if (p.participant_type === 'kid') {
        return `${p.kid.first_name ?? ''} ${p.kid.last_name ?? ''}`
    } else {
        if (p.user) {
            return p.user.email ?? ''
        } else {
            return p.email ?? ''
        }
    }
}

const CartItemParticipants = ({item}) => {
    const {t, i18n} = useTranslation();
    const participants = item.participants ?? [];
    if (participants.length === 0) {
        return <></>
    }
    const names = participants.map(p => participantName(p));
    return (
        <>
            <Typography sx={{fontSize: "0.85rem"}} component="div">
                {t('Participants')}: <b>{names.join(', ')}</b></Typography>
        </>
    );
}


const CartItem = ({item, removeItem, patchItem}) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);

    const [quantity, setQuantity] = useState(item.quantity);
    const [updating, setUpdating] = useState(false);

    const disabled = !patchItem || updating || !item.supports_quantity_update;
    const updateQuantity = (n, itemQuantity) => {
        if (n === itemQuantity) {
            return;
        }
        setUpdating(true);
        patchItem(item.id, {
            quantity: n
        }).catch((error) => {
            setUpdating(false);
        }).then(() => {
            setUpdating(false);
        })
    }

    const handleUpdate = useRef(debounce(updateQuantity, 600));

    const handleIncrease = (e) => {
        let newQuantity = item.quantity;
        if (!isNaN(quantity) && quantity + 1 < 100) {
            newQuantity = quantity + 1;
        }
        setQuantity(newQuantity);
        handleUpdate.current(newQuantity, item.quantity);
    }
    const handleDecrease = (e) => {
        let newQuantity = 1;
        if (!isNaN(quantity) && quantity - 1 > 0) {
            newQuantity = quantity - 1;
        }
        setQuantity(newQuantity);
        handleUpdate.current(newQuantity, item.quantity);
    }

    const handleChange = (e, v) => {
        let value = parseInt(e.target.value);
        if (isNaN(value)) {
            setQuantity('');
        } else {
            if (value < 0) {
                value = 1;
            } else if (value > 100) {
                value = 100;
            }
            setQuantity(value);
            handleUpdate.current(value, item.quantity);
        }
    }

    const handleDeleteItem = (event) => {
        setLoading(true);
        removeItem(item.id).catch((error) => {
            setLoading(false);
        })
    }
    const width = 192;
    const getItemMediaStyle = (item) => {
        return item.discriminator === "SuntagoOrderItem" ? {
            width: "100%", objectFit: "contain", border: 5, borderColor: "transparent", borderStyle: "solid"
        } : {width: width, "float": "left"}
    }
    return (
        <Card sx={{display: 'flex'}}>
            <Box sx={{maxWidth: width, width: "30%", flexShrink: 0, alignSelf: "center"}}>
                <img src={item.thumbnail} alt={item.name} style={getItemMediaStyle(item)}/>
            </Box>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography>{t('cart.item.' + item.discriminator)}</Typography>
                        <Typography>Cena</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography component="div" variant="h6" sx={{color: "primary.main"}}>
                            {item.name}
                        </Typography>
                        <Typography component="div" variant="h6" sx={{color: "primary.main"}}>
                            <b>{formatMoney(item.total)}</b>
                        </Typography>
                    </Stack>

                    {item.hasOwnProperty("series") && <Typography sx={{fontSize: "0.85rem"}} component="div">
                        {t('series')}: <b dangerouslySetInnerHTML={{__html: item.series.full_name}}></b></Typography>}
                    {item.hasOwnProperty("team") && item.team !== null &&
                    <Typography sx={{fontSize: "0.85rem"}} component="div">
                        {t('team')}: <b>{item.team.name}</b></Typography>}
                    <CartItemParticipants item={item}/>
                    {item.hasOwnProperty("subscription_order_item") && item.subscription_order_item !== null &&
                    <Typography sx={{fontSize: "0.85rem"}} component="div">
                        {t('cart.item.SubscriptionOrderItem')}: <b>{item.subscription_order_item.name}</b></Typography>}
                    {item.hasOwnProperty("subscription") && item.subscription !== null &&
                    <Typography sx={{fontSize: "0.85rem"}} component="div">
                        {t('cart.item.SubscriptionOrderItem')}: <b>{item.subscription.id}</b></Typography>}
                    {item.hasOwnProperty("series") && !["kids", "games", "treningi"].includes(item.series.event.event_type.external_id) && <Alert severity="info" color="warning">W ramach tego startu otrzymasz od nas 100 zł, które wykorzystasz na zakupy w sklepie - <a href="//sklep.runmageddon.pl" target="_blank" rel="noreferrer">sklep.runmageddon.pl</a>. Specjalny kod otrzymasz od razu po opłaceniu biegu i znajdziesz go w zakładce TWOJE ZNIŻKI!</Alert>}

                    {Object.values(item.adjustments).map((adjustment, index) => {
                        return <Typography sx={{fontSize: "0.85rem"}} component="div" key={adjustment.id}>
                            {t('cart.adjustment.' + adjustment.origin)}: {adjustment.label}&nbsp;
                            <Typography component="span" sx={{color: "primary.main", fontWeight: "bold", fontSize:"inherit"}}>{formatMoney(adjustment.money)}</Typography>

                        </Typography>
                    })}

                    {item.discriminator === "SuntagoOrderItem" && <Typography sx={{fontSize: "0.85rem"}} component="div">
                        {t('cart.SuntagoDescription')} </Typography>}

                    <Stack direction="row" justifyContent="space-between" sx={{mt: 3}}>
                        <Box sx={{"display": "inline-block", position: "relative",}}>
                            <TextField
                                placeholder={t('cart.quantity')}
                                variant="outlined"
                                value={quantity}
                                onChange={handleChange}
                                size="small"
                                color="primary"
                                fullWidth={false}
                                disabled={disabled}
                                InputProps={{
                                    sx: {
                                        input: {
                                            width: "32px",
                                            textAlign: "center"
                                        }
                                    },
                                    startAdornment: <InputAdornment position="start">
                                        <IconButton
                                            size="small"
                                            type="button"
                                            disabled={disabled}
                                            // sx={{ p: '10px' }}
                                            onClick={handleDecrease}
                                            aria-label="search">
                                            <RemoveIcon/>
                                        </IconButton>
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            type="button"
                                            disabled={disabled}
                                            // sx={{ p: '10px' }}
                                            onClick={handleIncrease}
                                            aria-label="search">
                                            <AddIcon/>
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            {updating && <LinearProgress
                                sx={{height: "1px", position: "absolute", left: "4px", bottom: "0px", right: "4px"}}
                            />}
                        </Box>
                        <Box>
                            {removeItem && item.discriminator !== "SuntagoOrderItem" &&

                            <IconButton
                                aria-label="delete"
                                color="error"
                                size="small"
                                disabled={loading}
                                onClick={handleDeleteItem}
                            >
                                {loading && <CircularProgress size="1em"/>}
                                {!loading && <DeleteIcon fontSize="inherit"/>}
                            </IconButton>
                            }
                        </Box>
                    </Stack>
                </CardContent>
            </Box>
        </Card>
    );
}

export default CartItem