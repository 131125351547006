import {Box, Card, CardContent, ImageList, ImageListItem, Tab, Tabs, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import { useNavigate, useParams} from "react-router-dom";
import {Loading} from "../../components/Loading";
import {client} from "../../lib/Client";
import {useTranslation} from "react-i18next";
import {DateDiff, formatDates, formatMoney, getCurrentPrice, getNextPrice, getOmnibusPrice} from "../../lib/utils";
import {useWidth} from "../../lib/hooks";
import OmnibusPrice from "../../components/Common/OmnibusPrice";
import GroupBuyInfo from "../../components/SignUp/GroupBuyInfo";
import {SignUpStepper} from "../../components/SignUp/SignUpStepper";
import {useSelector} from "react-redux";
import AlreadyInCart from "../../components/SignUp/AlreadyInCart";


const SignUp = () => {


    const {raceSlug} = useParams()
    const width = useWidth();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [loadingRace, setLoadingRace] = useState(true);
    const [race, setRace] = useState({})

    const fetchRace = () => {
        setLoadingRace(true);
        return client.race(raceSlug).then((response) => {
            setRace(response.data);
            setLoadingRace(false)
        }).catch((error) => {
            console.log('There was an error fetching race', error)
        })
    }

    useEffect(() => { fetchRace(); }, [raceSlug]);


    if (loadingRace) {
        return <Loading/>
    }
    const currentPrice = getCurrentPrice(race.prices);
    const omnibusPrice = getOmnibusPrice(race.prices);
    const nextPrice = getNextPrice(race.prices);
    const name = race.event ? race.event.name : race.name;
    const place = race.event ? race.event.place : race.place;
    const image = race.event ? race.event.image : race.image;
    let dates = "";
    if (race.event_type.id !== 51) {
        dates = race.event ? formatDates(race.event) : formatDates(race);
    }
    const imgWidth = width === "xl" ? "25%": "35%";

    return <>
        <Card sx={{ display: 'flex', mb: 3 }}>
            <Box sx={{width: imgWidth , alignSelf: "center"}} hidden={!["md", "lg", "xl"].includes(width)}>
                <img src={image} alt={name} style={{width: "100%", float: "left"}}/>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: width == "xs" ? 'column' : 'row' }}>
                <CardContent sx={{ flex: '100%' }}>
                    <Typography variant="h6">Zapisujesz się na</Typography>
                    <Typography variant="h5" sx={{color: "primary.main"}}>{name} { place}</Typography>
                    <Typography variant="h5"> {dates}</Typography>
                    <Typography variant="h6" sx={{mt:2}}>
                        Formuła: <Typography variant="h6" component="span" sx={{color: "primary.main"}}>{race.event_type.name}</Typography>
                    </Typography>
                </CardContent>
                <CardContent sx={{ flex: '5%' }}>
                    <Typography variant="h6" sx={{whiteSpace:"nowrap"}}>Aktualna cena</Typography>
                    <Typography variant="h5" sx={{color: "primary.main"}}>{formatMoney(currentPrice.price)}</Typography>
                    <OmnibusPrice price={omnibusPrice}/>
                    {currentPrice.price.amount != nextPrice.price.amount &&
                    <>
                        <Typography variant="h6" sx={{mt: 2, whiteSpace:"nowrap"}}>{t('prices.next', {count: DateDiff.inDays(nextPrice.dateFrom)})}</Typography>
                        <Typography variant="h5" sx={{color: "gray", textDecoration: "line-through"}}>{formatMoney(nextPrice.price)}</Typography>
                    </>
                    }
                </CardContent>
            </Box>
        </Card>
        <GroupBuyInfo race={race}></GroupBuyInfo>
       <SignUpStepper race={race}></SignUpStepper>
    </>
}

export default SignUp;
